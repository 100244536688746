body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background;
  color: $teal;
  font-family: $sansSerif;
  font-weight: 200;
  font-size: 1.6rem;
}

a {
  color: $teal;
}

strong {
  text-transform: uppercase;
  font-family: $sansSerif;
  font-weight: bold;
}

.container {
  max-width: 960px;
  padding: 4rem 1rem;
}

.title {
  text-transform: uppercase;
  font-family: $sansSerif;
  font-size: 35px;
  line-height: 35px;
}


.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 8rem);



  &__box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
}

.logo {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    text-transform: uppercase;
    font-family: $sansSerif;
    font-size: 35px;
    line-height: 35px;
    text-align: left;
    margin-left: 40px;

    @media only screen and (max-width: 600px) {
      margin-left: 0px;
      text-align: center;
    }

    &--dark {
      display: block;
      opacity: 0.6;
    }
  }

  &__spiral {
    -webkit-animation: rotating 20s linear infinite;
    -moz-animation: rotating 20s linear infinite;
    -ms-animation: rotating 20s linear infinite;
    -o-animation: rotating 20s linear infinite;
    animation: rotating 20s linear infinite;
  }

  &:before {
    content: '';
    position: absolute;
    width: 140px;
    height: 140px;
    background-color: $background;
    border-radius: 70px;
    z-index: 10;
  }

  &:after {
    content: 'H';
    line-height: 80px;
    font-size: 80px;
    font-family: $serif;
    position: absolute;
    width: 80px;
    height: 80px;
    z-index: 20;
    text-align: center;
  }
}

.description {
  text-align: center;
  padding: 20px;

  &__title {
    font-weight: 200;
  }

  &__email {
    color: $teal;
    font-size: 1.5em;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
